<template>
  <el-dialog
    custom-class="dialog-custom edarticle"
    :append-to-body="true"
    :close-on-click-modal="false"
    :title="vtitle"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <div class="article">
      <el-form
        ref="form"
        v-loading="loading"
        :model="articleModal"
        :rules="rules"
        label-width="60px"
      >
        <el-form-item
          label="标题"
          prop="spaperTitle"
        >
          <el-input
            v-model="articleModal.spaperTitle"
            placeholder="请输入标题"
            maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label=""
          prop="spaperDescribe"
        >
          <el-upload
            action
            :http-request="Upload"
            name="video"
            class="avatar-uploader"
            multiple
            :before-upload="beforeUploadVideo"
            :show-file-list="false"
          />
          <quill-editor
            ref="myQuillEditor"
            v-model.trim="articleModal.spaperDescribe"
            v-loading="quillUpdateImg"
            :options="editorOption"
            @change="onEditorChange"
          />
        </el-form-item>
        <el-form-item
          label=""
        >
          <div
            class="view_art"
            @click="openArtView()"
          >
            预览
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="footer"
      >
        <z-button
          type="primary"
          margin="0 20px"
          @click="push('form')"
        >
          {{ artbtnword }}
        </z-button>
        <z-button
          type="info"
          margin="0 20px"
          @click="handleClose"
        >
          取消
        </z-button>
      </div>
    </div>
    <view-art-dialog
      v-if="visibleviewDialog"
      :visible.sync="visibleviewDialog"
      :art-param="artParam"
    />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { client } from '../../api/ali-oss';
import { getoss } from '../../api/video';
import { addmanager } from '../../api/manage';
import viewArtDialog from './viewArtDialog';
import { deepClone } from '../../../public/js/util';

export default {
  name: 'EditArticleDialog',
  components: {
    viewArtDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentParam: {
      type: Object,
      default() { return {}; },

    },
  },
  computed: {
    ...mapGetters(['brandInfo']),
  },
  data() {
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['link', 'image'],
      ['clean'],

    ];
    const validateTitle = (rule, value, callback) => {
      console.log(value.trim().length <= 0);
      if (value.trim().length <= 0) {
        callback(new Error('标题不能为空'));
      } else {
        callback();
      }
    };
    return {
      html: '',
      text: '',
      artbtnword: '',
      vtitle: '',
      articleModal: {
        spaperTitle: '',
        spaperDescribe: '',
        pkid: 0,
      },
      rules: {
        spaperTitle: [
          { required: true, message: '请输入标题！', trigger: 'blur' },
          { validator: validateTitle, trigger: 'blur' },
        ],
        spaperDescribe: [{ required: true, message: '内容不能为空！', trigger: 'blur' }],
      },
      editorOption: {
        placeholder: '',
        theme: 'snow', // or 'bubble'
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image(value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector('.avatar-uploader input').click();
                } else {
                  this.quill.format('image', false);
                }
              },
            },
          },
        },
      },
      quillUpdateImg: false,
      loading: false,
      proAut: {},
      visibleviewDialog: false,
      artParam: {},
    };
  },
  mounted() {
    this.getproauthor();
    if (this.currentParam.pkid) {
      this.vtitle = '修改文章';
      this.artbtnword = '保存';
      const param = deepClone(this.currentParam);
      this.articleModal = {
        spaperTitle: param.spaperTitle, // 文章标题
        spaperDescribe: param.spaperDescribe,
        pkid: param.pkid,
      };
      this.html = param.spaperDescribe;
      this.text = this.articleModal.spaperDescribe;
    } else {
      this.vtitle = '发布文章';
      this.artbtnword = '发布';
      this.articleModal = {
        spaperTitle: '',
        spaperDescribe: '',
        pkid: 0,
      };
    }
  },
  methods: {
    getproauthor() {
      getoss().then((res) => {
        if (res.code === 0) {
          this.proAut = res.data;
        }
      });
    },
    handleClose() {
      this.$emit('update:visible', false);
    },
    onEditorChange({ editor, html, text }) { // 内容改变事件
      this.$refs.form.validate();
      console.log('html', html);
      this.html = html;
      console.log('text', text);
      this.text = text;
      this.articleModal.spaperDescribe = html;
    },
    Upload(file) {
      const { quill } = this.$refs.myQuillEditor;
      this.quillUpdateImg = true;
      let config = {};
      if (this.proAut) {
        config = {
          region: this.proAut.region, // 后台给
          accessKeyId: this.proAut.accessKeyId, // 后台给
          accessKeySecret: this.proAut.accessKeySecret, // 后台给
          bucket: this.proAut.bucket,
          stsToken: this.proAut.stsToken,
        };
      }
      console.log(config);
      const that = this;
      that.showcove = true;
      async function multipartUpload() {
        const temporary = file.file.name.lastIndexOf('.');
        const fileNameLength = file.file.name.length;
        const fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength,
        );
        // let fileName = getFileNameUUID() + "." + fileFormat;
        const fileName = file.file.name;
        client(config)
          .multipartUpload(`videoTest/${fileName}`, file.file, {
            progress(p) {
              // p进度条的值
              console.log(p);
              // that.showProgress = true;
              that.coProgress = Math.floor(p * 100);
            },
          })
          .then((result) => {
            // 上传成功返回值，可针对项目需求写其他逻辑
            console.log('返回2', result.res.requestUrls[0]);
            // eslint-disable-next-line eqeqeq
            if (result.res.requestUrls[0].indexOf('?uploadId') != -1) {
              const imgurl = that.getCaption(result.res.requestUrls[0], '?uploadId');
              const length = quill.getSelection().index;
              quill.insertEmbed(length, 'image', imgurl);
              quill.setSelection(length + 1);
            } else {
              const imgurl = result.res.requestUrls[0];
              const length = quill.getSelection().index;
              quill.insertEmbed(length, 'image', imgurl);
              quill.setSelection(length + 1);
            }
            that.quillUpdateImg = false;
          })
          .catch((err) => {
            that.$message.error('上传失败，请联系管理员');
            console.log('err:', err);
          });
      }
      multipartUpload();
    },
    // 上传封面图验证
    beforeUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      const limitStr = ['《', '》', '%', '#', '*', ';', '[', ']', '【', '】', '{', '}', '&', '$'];
      const bool = !limitStr.find((x) => file.name.indexOf(x) > -1);
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      if (!bool) {
        this.$message.error('图片命名不得包含非法字符!');
      }
      return isLt2M && bool;
    },
    // 截取指定字符后的内容
    getCaption(url, parameter) {
      const index = url.indexOf(parameter);
      const url2 = url.substring(0, index);
      return url2;
    },
    openArtView() {
      this.visibleviewDialog = true;
      const param = {
        spaperTitle: this.articleModal.spaperTitle,
        spaperDescribe: this.articleModal.spaperDescribe,
      };
      this.artParam = param;
    },
    push(formName) {
      debugger;
      const text = this.text.replace(/\s+/g, '');
      const html = this.html.indexOf('img');
      if (!text) {
        if (html === -1) {
          this.$message.closeAll();
          this.$message({
            type: 'warning',
            showClose: true,
            duration: 3000,
            message: '内容不能为空',
          });
          return;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 新建或更新
          if (this.articleModal.pkid === 0) {
            this.newmanager();
          } else {
            this.updatamanager();
          }
        }
      });
    },
    newmanager() {
      const param = {
        nbrandId: this.brandInfo.nbrandId,
        sbrandName: this.brandInfo.sbrandName,
        spaperDescribe: this.articleModal.spaperDescribe,
        spaperTitle: this.articleModal.spaperTitle,
      };
      addmanager(param).then((res) => {
        this.$message.success('发布成功');
        this.$emit('refreshList');
      })
    },
    updatamanager() {
      const param = {
        nbrandId: this.brandInfo.nbrandId,
        pkid: this.articleModal.pkid,
        sbrandName: this.brandInfo.sbrandName,
        spaperDescribe: this.articleModal.spaperDescribe,
        spaperTitle: this.articleModal.spaperTitle,
      };
      addmanager(param).then((res) => {
        this.$message.success('修改成功');
        this.$emit('refreshList');
      })
    },
  },
};
</script>

<style lang="scss">
  strong{
    font-weight:bold!important;
  }
  em{
    font-style: italic!important;
  }
  .edarticle{
    width: 1200px!important;
  }
  .article {
    .quill-editor{
      margin-top: -27px;
    }
    .el-dialog__body {
      overflow-x: auto;
    }
    .ql-editor.ql-blank,
    .ql-editor {
      height: 420px;
    }
    .el-upload--picture-card {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 108px;
      height: 108px;
      line-height: 106px;
      vertical-align: top;
    }
    .el-loading-spinner i{
      color: #28cccc;
    }
    .el-loading-text{
      color: #28cccc;
    }
    .footer{
      text-align: center;
      margin-top: 42px;
    }
    .view_art{
      cursor: pointer;
      width: 30px;
    }
  }
</style>
