<template>
  <div>
    <div class="main-container manage">
      <div class="row-panel search-panel">
        <el-input
          v-model.trim="searchParams.skey"
          class="search-item"
          style="width: 260px;"
          placeholder="文章标题"
          size="mini"
          clearable
          @clear="querartmesList"
          @keyup.enter.native="searchInit"
        />
        <z-button
          type="primary"
          size="medium"
          class="search-item"
          @click="searchInit"
        >
          查询
        </z-button>
        <div class="search-item">
          <span class="search-label">发布日期</span>
          <el-date-picker
            v-model="searchParams.beginAndEndTime"
            size="mini"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            @change="fetchartmessList"
          />
        </div>
      </div>
      <div class="row-panel relative">
        <div
          class="release_btn"
          @click="openarticle()"
        >
          <z-button
            type="primary"
            size="medium"
          >
            <i class="iconfont fabuhuodong" />发布文章
          </z-button>
        </div>
        <el-tabs
          v-model="searchParams.nstatus"
          @tab-click="changeTab"
        >
          <el-tab-pane
            v-for="(item,index) in tabStatus"
            :ref="'tab' + item.value"
            :key="index"
            :label="item.text"
            :name="item.value"
          />
        </el-tabs>
        <el-table
          v-loading="loading"
          :data="manageList"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column
            prop="npaperId"
            label="文章ID"
            min-width="177"
          >
            <template slot-scope="scope">
              {{ scope.row.npaperId }}
            </template>
          </el-table-column>
          <el-table-column
            prop="spaperTitle"
            label="标题"
            min-width="632"
          >
            <template slot-scope="scope">
              <copy-text :copy-text="scope.row.spaperTitle" />
            </template>
          </el-table-column>
          <el-table-column
            prop="nviewNum"
            label="阅读量"
            min-width="224"
          >
            <template slot-scope="scope">
              {{ scope.row.nviewNum }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="searchParams.nstatus==1"
            prop="dpushTime"
            label="发布时间 "
            min-width="160"
          >
            <template slot-scope="scope">
              {{ scope.row.dpushTime }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="searchParams.nstatus==0"
            prop="dcreateTime"
            label="上传时间"
            min-width="160"
          >
            <template slot-scope="scope">
              {{ scope.row.dcreateTime }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            min-width="200"
          >
            <template slot-scope="scope">
              <z-button
                type="text"
                size="small"
                :color="2"
                font-size="small"
                @click="queryArt(scope.row)"
              >
                查看
              </z-button>
              <z-button
                type="text"
                size="small"
                :color="2"
                font-size="small"
                @click="editArt(scope.row)"
              >
                修改
              </z-button>
              <z-button
                type="text"
                size="small"
                :color="2"
                font-size="small"
              >
                <span
                  v-if="scope.row.nstatus==0"
                  @click="upAndlow(scope.row,1)"
                >发布</span>
                <span
                  v-if="scope.row.nstatus==1"
                  @click="upAndlow(scope.row,0)"
                >下架</span>
              </z-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          popper-class="paginationComCls"
          style="text-align:center;margin-top:20px"
          layout="sizes, prev, pager, next,total"
          :total="total"
          :page-sizes="[12, 20, 30, 40, 50]"
          :page-size="pageSize"
          :current-page="pageNum"
          @current-change="current_page_change"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <edit-article-dialog
      v-if="visibleDialog"
      :visible.sync="visibleDialog"
      :current-param="currentParam"
      @refreshList="successToEdit"
    />
    <view-art-dialog
      v-if="visibleDialog2"
      :visible.sync="visibleDialog2"
      :art-param="artParam"
    />
    <warm-pop
      :pop="pop.ispop"
      :is-cancle="true"
      :close-img="true"
      :icon="-11"
      :title="pop.title"
      @close="close"
    >
      <z-button
        slot="btn"
        type="primary"
        @click="comfirmxj()"
      >
        确认
      </z-button>
    </warm-pop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { managerList, offart } from '../../api/manage';
import { dealBeginTime, dealEndTime } from '../../../public/js/util';
import copyText from '../../components/common/copyText';
import editArticleDialog from './editArticleDialog';
import viewArtDialog from './viewArtDialog';
import warmPop from '../../components/common/warmPop';

export default {
  name: 'ArticleManage',
  components: {
    copyText,
    editArticleDialog,
    viewArtDialog,
    warmPop,
  },
  data() {
    return {
      pop: {
        ispop: false,
        title: '',
      },
      manageList: [],
      searchParams: {
        skey: '',
        beginAndEndTime: '',
        nstatus: '1',
      },
      pageNum: 1,
      pageSize: 12,
      total: 0,
      tabStatus: [
        {
          text: '发布中',
          value: '1',
        },
        {
          text: '已下架',
          value: '0',
        },
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
      loading: false,
      visibleDialog: false,
      currentParam: {},
      artParam: {},
      visibleDialog2: false,
      offparam: {},
    };
  },
  computed: {
    ...mapGetters(['brandInfo']),
    beginTime() {
      return this.searchParams.beginAndEndTime ? dealBeginTime(this.searchParams.beginAndEndTime[0]) : '';
    },
    endTime() {
      return this.searchParams.beginAndEndTime ? dealEndTime(this.searchParams.beginAndEndTime[1]) : '';
    },
  },
  watch: {
    'searchParams.nstatus': function (newName, oldName) {
      this.pageNum = 1;
      this.querartmesList();
    },
    beginTime: {
      handler(newVal, oldVal) {
        console.log('时间', this.searchParams.beginAndEndTime);
      },
    },
    endTime: {
      handler(newVal, oldVal) {
        console.log('时间w', this.searchParams.beginAndEndTime);
      },
    },
  },
  mounted() {
    this.querartmesList();
  },
  methods: {
    comfirmxj() {
      offart(this.offparam).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.e.msg);
          this.close();
          this.querartmesList();
        } else {
          this.$message.success('失败');
        }
      }).catch((e) => this.$message.error(e.message));
    },
    close() {
      this.pop.ispop = false;
      this.offparam = {};
    },
    upAndlow(item, index) {
      if (index === 0) {
        this.pop.title = '确定下架该文章？';
      } else {
        this.pop.title = '确定发布该文章？';
      }
      const param = {
        nstatus: index,
        pkid: item.pkid,
        nbrandId: this.brandInfo.nbrandId,
      };
      if (param) {
        this.offparam = param;
      }
      this.pop.ispop = true;
    },
    queryArt(param) {
      this.visibleDialog2 = true;
      this.artParam = param;
    },
    successToEdit() {
      this.visibleDialog = false;
      this.querartmesList();
    },
    querartmesList() {
      this.loading = true;
      const param = {
        dpushTimeBegin: this.beginTime,
        dpushTimeEnd: this.endTime,
        nstatus: parseInt(this.searchParams.nstatus, 10),
        spaperTitle: this.searchParams.skey,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      managerList(param).then((res) => {
        this.loading = false;
        this.manageList = res.data.datas;
        this.total = res.data.total;
      })
    },
    searchInit() {
      this.pageNum = 1;
      this.querartmesList();
    },
    fetchartmessList() {
      this.pageNum = 1;
      this.querartmesList();
    },
    openarticle() {
      this.visibleDialog = true;
      this.currentParam = {};
    },
    editArt(param) {
      this.visibleDialog = true;
      this.currentParam = param;
    },
    changeTab(tab) {
      const { name } = tab;
      this.searchParams.nstatus = name;
    },
    current_page_change(param) {
      this.pageNum = param;
      this.querartmesList();
    },
    handleSizeChange(param) {
      this.pageNum = 1;
      this.pageSize = param;
      this.querartmesList();
    },
  },
};
</script>

<style scoped lang="scss">
  .manage {
    .relative {
      position: relative;
    }

    .release_btn {
      position: absolute;
      right: 23px;
      top: 17px;
      z-index: 33;
    }
  }

</style>
<style lang="scss">
  .manage{
    .el-table .el-table__cell .cell{
      line-height: 1.4em !important;
    }
    .el-table .el-table__cell{
      padding: 0px 5px !important;
    }
  }

</style>
