<template>
  <el-dialog
    custom-class="dialog-custom viewArt"
    :append-to-body="true"
    :close-on-click-modal="false"
    title="最新动态"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <div class="art_content">
      <div class="act_title">
        {{ artname }}
      </div>
      <div class="art-time">
        {{ time }}
      </div>
      <div class="ql-container ql-snow">
        <div
          class="ql-editor"
          v-html="artontent"
        />
      </div>
      <div class="readnum">
        阅读  <span>{{ nviewNum }}</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { deepClone } from '../../../public/js/util';
import '../../assets/css/quill.snow.css';

export default {
  name: 'ViewArtDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    artParam: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      artname: '',
      artontent: '',
      nviewNum: 0,
      time: '',
    };
  },
  mounted() {
    if (this.artParam) {
      // debugger
      const param = deepClone(this.artParam);
      this.artname = param.spaperTitle;
      this.artontent = param.spaperDescribe;
      this.time = (param.dpushTime.trim().split(' '))[0];
      if (param.nviewNum) {
        this.nviewNum = param.nviewNum;
      }
      console.log(this.artParam);
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="scss">
  .viewArt{
    /*.el-dialog__header {*/
    /*  justify-content: center !important;*/
    /*}*/
    /*.el-dialog__title {*/
    /*  font-size: 22px !important;*/
    /*}*/
  }
  .art_content {
    position: relative;
    margin: auto;
    .ql-container.ql-snow{
      border: none!important;
    }
    .art-time{
      text-align: right;
      margin-bottom: 10px;
    }
    .readnum{
      margin-top: 20px;
    }
    .act_title{
      text-align: center;
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: bold;
    }
}
</style>
